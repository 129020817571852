<script lang="ts">
  export let sectionTitle = 'Section Title'
</script>
<h2>
  { sectionTitle}
</h2>

<style lang="scss">
  h2 {
    font-size: 3em;
    margin: 0 0 2rem 0;
  }
</style>